import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import Autosave from 'stimulus-rails-autosave';
import Notification from 'stimulus-notification';
import NestedForm from 'stimulus-rails-nested-form';
import { Alert, ColorPreview, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components";

const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', {eager: true});
registerControllers(application, controllers);

application.register('autosave', Autosave);
application.register('notification', Notification);
application.register('nested-form', NestedForm);

application.register('color-preview', ColorPreview);
application.register('dropdown', Dropdown);
application.register('modal', Modal);
application.register('popover', Popover);
application.register('slideover', Slideover);
application.register('tabs', Tabs);
application.register('toggle', Toggle);

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log('navigate connecte');
  }

  to(e) {
    e.preventDefault();
    const { url } = e.target.dataset;

    this.element.src = url;
  }


  to2(e) {
    const { url, field } = e.target.dataset;

    let params = new URLSearchParams();
    params.append(field, e.target.value);

    this.element.src = `${url}?${params}`;
  }
}

// Works with turbo-frame tag
// changes src for turbo_frame

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log('clear form connect');
    }
    clear() {
        this.element.reset();
    }
}
